import { isEmpty } from "lodash";
import parse from 'html-react-parser';

import Script from "next/script";
import { useEffect } from "react";

const GoogleTagManager = ({ code }) => {
  if (isEmpty(code)) { return; }

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'granted'
    });
  }, [])

  return (
    <Script strategy="lazyOnload">
      {parse(`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${code}');`)}
    </Script>
  )
}

export default GoogleTagManager