import { isEmpty } from "lodash";
import { gsap } from "gsap";

import Image from "next/image";

const BlTriptychPart = ({ item, wShowMore = false }) => {
  if (isEmpty(item)) {
    return;
  }

  if (wShowMore == true) {
   const handleShowMoreClick = (event) => {
    let clicker = event.currentTarget;
    let content = clicker.previousElementSibling;

    if (clicker.classList.contains('open')) {
      gsap.to(content, {height: 0});
      clicker.innerHTML = "Afficher";
      clicker.classList.remove('open');
    } else {
      gsap.to(content, {height: 'auto'});
      clicker.innerHTML = "Cacher";
      clicker.classList.add('open');
    }
   }

    return (
      <div className="bl-tryptich-part text-center text-white font-light">
        <Image
          src={item?.image?.sourceUrl}
          width={item?.image?.mediaDetails?.width}
          height={item?.image?.mediaDetails?.height}
          alt={item.titre}
          className="triptych-img mb-20 mx-auto w-auto h-auto"
        />
        <div className="w-[80%] mb-20 mx-auto ft-20">{item.smallTitle}</div>
        <div>
          <div className="h-0 overflow-hidden ft-15 px-[10px]">
            {item.content}
          </div>
          <div className="clicker underline underline-offset-4 hover:cursor-pointer hover:text-action" onClick={handleShowMoreClick}>Afficher</div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="bl-tryptich-part text-center">
        <Image
          src={item?.image?.sourceUrl}
          width={item?.image?.mediaDetails?.width}
          height={item?.image?.mediaDetails?.height}
          alt={item.titre}
          className="max-h-40 w-auto h-auto mb-15 mx-auto xl:max-h-50"
        />
        <div className="bl-tryptich-part-title w-80 mx-auto xl:w-125 ft-12 font-extralight text-white">{item.smallTitle}</div>
      </div>
    )
  }
}

export default BlTriptychPart