import { useGlobalContext } from "../../../contexts"
import BlTriptychPart from "./partials/BlTriptychPart";

const BlTriptych = ({ classes }) => {
  const { context } = useGlobalContext();

  if (context.isSet == false) {
    return;
  }

  return (
    <div className={`bl-triptych ${classes}`}>
      <div className="flex justify-between">
        <BlTriptychPart item={context.blTriptych.parts[0]} />
        <span className="mx-20 text-[30px] lg:mx-10 lg:text-[40px] lg:mx-5 lg:text-[50px] font-extralight text-white">+</span>
        <BlTriptychPart item={context.blTriptych.parts[1]} />
        <span className="mx-20 text-[30px] lg:mx-10 lg:text-[40px] lg:mx-5 lg:text-[50px] font-extralight text-white">+</span>
        <BlTriptychPart item={context.blTriptych.parts[2]} />
      </div>
    </div>
  )
}

export default BlTriptych