import { gsap } from "gsap";

import { useState } from "react";

import BtnPrimary from "../buttons/BtnPrimary";
import BtnTertiary from "../buttons/BtnTertiary";

const PopupCookies = ({
  acceptAllCookies,
  deniedAllCookies,
  onSavePreferences,
  setShowCookieContainer,
  cookiesData,
  closePopup,
}) => {
  const [isChecked, setIsChecked] = useState(Array(cookiesData.length).fill(true));
  const [isOpen, setIsOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);

  /**
   * Get name + index cookies on checkbox change
   * Send to parent component
   * @param {Event} event
   * @param {integer} index
   */
  const handleCheckboxChange = (event, index) => {
    const newIsChecked = [...isChecked];
    newIsChecked[index] = event.target.checked;
    setIsChecked(newIsChecked);
  };

  /**
   *  Open / close list popup
   * @param {integer} index of list
   */
  const toggleDesc = (index) => {
    if (openIndex === index && isOpen) {
      setIsOpen(false);
      setOpenIndex(null);
      gsap.to('.popup-cookie .content-cookie', { height: 0, opacity: 0, duration: 0.3, ease: "power1.in" });
    } else {
      setIsOpen(true);
      setOpenIndex(index);
      gsap.to('.popup-cookie .content-cookie', { height: 0, opacity: 0, duration: 0.3, ease: "power1.in" });
      gsap.to(`.popup-cookie .content-cookie.item-${index}`, { height: "auto", opacity: 1, duration: 0.3, ease: "power1.in" });
    }
  };

  /**
   * Save cookie preferences and send "preferences" to the parent component.
   * Ensure consistent keys using the getKeysObjectCookies function.
   * Keys are obtained from cookies.json (French).
   */
  const savePreference = () => {
    const preferences = {};
    const cookieKeys = Object.keys(cookiesData);
    isChecked.forEach((value, index) => {
      const cookieName = cookieKeys[index];
      const preferredKeyName = getKeysObjectCookies(cookieName);
      preferences[preferredKeyName] = value;
    });
    onSavePreferences(preferences);
  }

  /**
   * Retrieve the key from the JSON object.
   * The key must exactly match the key in cookies.json.
   * @param {Object} cookieName
   * @returns {string} The corresponding key.
   */
  const getKeysObjectCookies = (cookieName) => {
    const keyMap = {
      "Nécessaire": "necessary",
      "Analytique": "analytics",
      "Publicitaire": "publicity"
    };
    return keyMap[cookieName] || cookieName;
  }

  return (
    <div className={`popup-cookie`}>
      <div className="scrollbar-hide-horizontal content-wrapper w-full h-full">
        <div className="top-cookie flex items-center justify-between pb-5">
          <p className='h4 text-white mb-32'>Gestion des cookies</p>
        </div>
        <div className="list-cookies mb-32">
          <ul className="flex flex-col">
            {cookiesData.map((item, index) => (
              <li
                className="flex justify-between items-start overflow-hidden"
                key={item.name}
                id={item.content}
              >
                <div onClick={(e) => {
                  toggleDesc(index);
                  setOpenIndex(index);
                }}
                  className="cookie-item"
                >
                  <p className="list-name text-white" key={item.name} id={item.name}>{item.name}</p>
                  <div className={`content-cookie item-${index}`}>
                    <p className={`py-16 text-white ${index === openIndex ? "block" : "hidden"}`}>{item.content}</p>
                  </div>
                </div>
                {index === 0
                ? <div className="first-active text-white">Toujours activé</div>
                :
                  <div className="btn-checkbox-cookie relative h-full w-full">
                    <input
                      type="checkbox"
                      checked={isChecked[index]}
                      className="checkbox"
                      onChange={(event) => handleCheckboxChange(event, index)}
                      />
                    <div className="knobs"></div>
                    <div className="layer"></div>
                  </div>
                }
              </li>
            ))}
          </ul>
        </div>
        <div className={`flex flex-col lg:flex-row wrapper-btn-cookies`}>
          <BtnTertiary link={{ title: 'Enregistrer'}} onClick={() => { savePreference(); closePopup(); }} />
          <BtnPrimary link={{ title: 'Tout accepter'}} onClick={() => { acceptAllCookies(); closePopup(); setShowCookieContainer(false);}} />
          <BtnPrimary link={{ title: 'Tout refuser'}} nobackground={'true'} onClick={() => { deniedAllCookies(); closePopup(); setShowCookieContainer(false);}} />
        </div>
      </div>
    </div>
  )
}

export default PopupCookies