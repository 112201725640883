import { isEmpty } from "lodash";

import { useGlobalContext, useLayoutContext } from "../../../contexts";
import Link from "next/link";

import Lottie from "lottie-react";
import Blink from "../../../public/lottie/blink.json"

import Image from "next/image";

const Btn = ({type, classes, textClasses, iconClasses, title, uri, target, active, id, handleSearch, font, scrollTo}) => {
  const { context } = useGlobalContext();

  switch (type) {
    case 'header-test':
      const { menuStatus } = useLayoutContext();
      const handleHeaderTestClick = event => {
        if (menuStatus == 'menu-open') {
          let bgGroup = event.currentTarget.parentNode.parentNode.querySelector('.menu-wrapper .burger-group');
          if (bgGroup != null) {
            bgGroup.click();
          }
        }
      }

      if (isEmpty(context?.links?.expTest?.url)) { return null; }

      return (
        <Link
          href={context?.links?.expTest?.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={context?.links?.expTest?.target}
          className={`btn-header-test relative px-16 py-8 rounded-full bg-action2
            sm:inline-flex sm:items-center
            lg:px-20 lg:py-10 xl:px-25 xl:py-20
            transition-all group
            ${classes}
          `}
          onClick={handleHeaderTestClick}
        >
          <div className="bottom bg-btn-linear-gradient w-full h-full absolute top-0 left-0 rounded-full group-hover:opacity-0 transition-all"/>
          <span className="top hidden sm:block w-20 h-20 mr-10">
            <Lottie animationData={Blink} loop={true} className="w-full h-full transition-all group-hover:opacity-0" />
            <Image src="/images/svgs/sparkle-white.svg" width={20} height={20} alt={'scintillement'}
              className="w-full h-full absolute top-0 left-0 object-cover opacity-0 transition-all group-hover:opacity-100"
            />
          </span>
          <span className="top ft-14 text-action2 sm:hidden transition-all group-hover:text-white">Test gratuit</span>
          <span className="top hidden ft-14 text-action2 sm:block transition-all  group-hover:text-white">Tester l'expérience</span>
          <span className="top hidden sm:block w-20 h-20 ml-10">
            <Lottie animationData={Blink} loop={true} className="w-full h-full transition-all group-hover:opacity-0" />
            <Image src="/images/svgs/sparkle-white.svg" width={20} height={20} alt={'scintillement'}
              className="w-full h-full absolute top-0 left-0 object-cover opacity-0 transition-all group-hover:opacity-100"
            />
          </span>
        </Link>
      )
    case 'normal-test':
      if (isEmpty(context?.links?.contact?.url)) { return null; }
      return (
        <Link
          href={context?.links?.expTest?.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={context?.links?.expTest?.target}
          className={`btn-normal-test group relative rounded-full bg-action2
            flex items-center justify-center px-25 py-20 rounded-full
            ${classes}
          `}
        >
          <div className="bottom bg-btn-linear-gradient w-full h-full absolute top-0 left-0 rounded-full group-hover:opacity-0 transition-all"/>
          <span className="top block w-20 h-20 mr-10">
            <Lottie animationData={Blink} loop={true} className="w-full h-full transition-all group-hover:opacity-0" />
            <Image src="/images/svgs/sparkle-white.svg" width={20} height={20} alt={'scintillement'}
              className="w-full h-full absolute top-0 left-0 object-cover opacity-0 transition-all group-hover:opacity-100"
            />
          </span>
          <span className="top ft-14 text-action2 sm:block transition-all group-hover:text-white">{title == null ? 'Tester gratuitement' : title}</span>
          <span className="top block w-20 h-20 ml-10">
            <Lottie animationData={Blink} loop={true} className="w-full h-full transition-all group-hover:opacity-0" />
            <Image src="/images/svgs/sparkle-white.svg" width={20} height={20} alt={'scintillement'}
              className="w-full h-full absolute top-0 left-0 object-cover opacity-0 transition-all group-hover:opacity-100"
            />
          </span>
        </Link>
      )
    case '404':
      return (
        <Link
        href={'/'}
        className={`
          flex items-center justify-center px-25 py-20 rounded-full border border-white
          ${classes} group transition-all hover:cursor-pointer hover:border-action hover:bg-action
        `}
        >
          <span className="ft-14 text-white transition-all group-hover:text-action2">Retour à l'accueil</span>
        </Link>
      )
    case 'contact-no-fun':
      if (isEmpty(context?.links?.contact?.url)) { return null; }
      return (
        <Link
        href={context?.links?.contact?.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
        target={context?.links?.contact?.target}
        className={`
          flex items-center justify-center px-25 py-20 rounded-full border border-action
          ${classes} group transition-all hover:cursor-pointer hover:bg-action
        `}
        >
          <span className="ft-14 text-action transition-all group-hover:text-action2">{context?.links?.contact?.title}</span>
        </Link>
      )
    case 'contact-no-fun-style':
      return (
        <Link
        href={uri.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
        target={target}
        className={`
          flex items-center justify-center px-40 py-20 rounded-full border border-action
          ${classes} group transition-all hover:cursor-pointer hover:bg-action
        `}
        >
          <span className="ft-14 text-action transition-all group-hover:text-action2">{title}</span>
        </Link>
      )
    case 'shop':
      if (isEmpty(context?.links?.shop?.url)) { return null; }
      if (isEmpty(title)) {
        title = context?.links?.shop?.title;
      }
      return (
        <Link
          href={context?.links?.shop?.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={context?.links?.shop?.target}
          className={`btn-shop group
            flex items-center justify-center w-41 h-41 rounded-full bg-text/30
            xl:w-61 xl:h-61
            2xl:w-auto 2xl:h-auto 2xl:px-25 2xl:py-20
            ${classes}
          `}
        >
          <span className="hidden 2xl:block 2xl:mr-10 ft-14 text-white transition-colors group-hover:text-primary">{title}</span>
          <span className="icon icon-cart text-white xl:text-[22px] 2xl:text-base transition-colors group-hover:text-primary"></span>
        </Link>
      )
    case 'shop-outside-header':
      if (isEmpty(context?.links?.shop?.url)) { return null; }
      if (isEmpty(title)) {
        title = context?.links?.shop?.title;
      }
      return (
        <Link
          href={context?.links?.shop?.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={context?.links?.shop?.target}
          className={`group inline-flex items-center justify-center rounded-full bg-text/30 px-25 py-20 ${classes}`}
        >
          <span className="mr-10 ft-14 text-white transition-colors group-hover:text-primary">{title}</span>
          <span className="icon icon-cart text-white xl:text-[22px] 2xl:text-base transition-colors group-hover:text-primary"></span>
        </Link>
      )
    case 'cta-2':
      if (isEmpty(uri)) { return null; }
      return (
        <Link
          href={uri.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={target}
          className={`
            flex lg:inline-flex items-center justify-center px-40 py-15 rounded-full border border-primary bg-white/50
            ${classes} group transition-all hover:cursor-pointer hover:bg-action hover:border-action
          `}
        >
          <span className="mr-6 ft-14 text-action2 group-hover:text-white">{title}</span>
          <span className="icon icon-arrow text-action2 text-[10px] group-hover:text-white"></span>
        </Link>
      )
    case 'cta-2-2':
      if (isEmpty(uri) && isEmpty(title)) { return null; }
      if (isEmpty(uri) ){
        return (
          <div className={`
              flex lg:inline-flex items-center justify-center px-40 py-20 rounded-full border border-actionlight
              ${classes} group transition-all hover:cursor-pointer hover:bg-action hover:border-action
            `}
          >
            <span className="mr-6 ft-14 text-white">{title}</span>
            <span className={`icon icon-arrow text-white text-[10px] ${iconClasses}`}></span>
          </div>
        )
      }
      return (
        <Link
          href={uri.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={target}
          className={`
            flex lg:inline-flex items-center bg-transparent justify-center px-40 py-20 rounded-full border border-actionlight
            ${classes} transition-all hover:cursor-pointer hover:bg-action hover:border-action
          `}
        >
          <span className="mr-6 ft-14 text-white">{title}</span>
          <span className={`icon icon-arrow text-white text-[10px] ${iconClasses}`}></span>
        </Link>
      )
    case 'cta-2-2-group':
      return (
        <div className={`btn-cta-2-2-group
            flex lg:inline-flex items-center justify-center px-40 py-20 rounded-full border border-actionlight
            ${classes} transition-all group-hover:cursor-pointer group-hover:bg-action group-hover:border-action
          `}
        >
          <span className="mr-6 ft-14 text-white">{title}</span>
          <span className={`icon icon-arrow text-white text-[10px] ${iconClasses}`}></span>
        </div>
      )
    case 'cta-2-2-templateWhite':
      if (isEmpty(uri) ){
        return (
          <div className={`
              flex lg:inline-flex items-center justify-center px-40 py-20 rounded-full border border-actionlight
              ${classes} group group-hover:bg-action hover:bg-action transition-all hover:cursor-pointer
            `}
          >
            <span className={`mr-6 ft-14 text-primary group-hover:text-white transition-all ${textClasses}`}>{title}</span>
            <span className={`icon icon-arrow text-primary text-[10px] group-hover:text-white transition-all ${iconClasses}`}></span>
          </div>
        )
      }
      return (
        <Link
          href={uri.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={target}
          className={`
            flex lg:inline-flex items-center justify-center px-40 py-20 rounded-full border border-actionlight
            ${classes} group hover:bg-action transition-all
          `}
        >
          <span className="mr-6 ft-14 text-white group-hover:text-white transition-all">{title}</span>
          <span className="icon icon-arrow text-white text-[10px] group-hover:text-white transition-all"></span>
        </Link>
      )
    case 'cta-light-2':
      if (isEmpty(uri)) {
        return (
          <div
            className={`cta-light-2 group hover:cursor-pointer
              flex items-center
              ${classes}
            `}
          >
            <span className={`text ft-12 leading-none underline decoration-solid underline-offset-[6px] transition-all group-hover:text-action ${textClasses}`}>{title}</span>
            <span className={`ml-6 icon icon-btn-arrow text-[10px] transition-all group-hover:text-action group-hover:rotate-45 ${iconClasses}`}></span>
          </div>
        )
      }
      return (
        <Link
          href={uri.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={target}
          className={`cta-light-2 group hover:cursor-pointer
            flex items-center
            ${classes}
          `}
        >
          <span className={`text ft-12 leading-none underline decoration-solid underline-offset-[6px] transition-all group-hover:text-action ${textClasses}`}>{title}</span>
          <span className={`ml-6 icon icon-btn-arrow text-[10px] transition-all group-hover:text-action group-hover:rotate-45 ${iconClasses}`}></span>
        </Link>
      )
    case 'cta-4':
      return (
        <Link
          href={uri.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={target}
          className={`
            inline-flex items-center justify-center px-40 py-15 rounded-full border border-secondary
            ${classes}
          `}
        >
          <span className="mr-6 ft-14 text-secondary">{title}</span>
          <span className="icon icon-arrow text-secondary text-[10px]"></span>
        </Link>
      )
    case 'cta-5':
      if (isEmpty(uri)) {
        if (isEmpty(scrollTo)) { return null; }

        const handleClick = () => {
          let el = document.querySelector(scrollTo);

          if (el != null) {
            let offset = document.querySelector('header')?.clientHeight;
            let y = el.getBoundingClientRect().top + window.scrollY - (offset / 2);

            window.scrollTo({top: y, behavior: 'smooth'});
          }
        }

        return (
        <div className={`
          inline-flex items-center justify-center text-center px-40 py-15 rounded-full border border-white transition-all group hover:cursor-pointer hover:bg-white
          ${classes}
        `} onClick={handleClick}>
          <span className="ft-14 text-white group-hover:text-action2">{title}</span>
        </div>
        )
      }
      return (
        <Link
          href={uri.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={target}
          className={`
            inline-flex items-center justify-center px-40 py-15 rounded-full border border-white transition-all group hover:cursor-pointer hover:bg-white
            ${classes}
          `}
        >
          <span className="ft-14 text-white group-hover:text-action2">{title}</span>
        </Link>
      )
    case 'link-button':
      if (isEmpty(uri)) {
        return (
          <div
            className={`link-button group hover:cursor-pointer
              w-full flex items-center justify-between text-white
              ${classes}
            `}
          >
            <span className="text-[20px] 3xl:text-[22px] 4xl:text-[25px] 5xl:text-[28px] 6xl:text-[33px] font-light text-white leading-none transition-all group-hover:translate-x-10">{title}</span>
            <span className="ml-6 icon icon-btn-arrow text-[12px] lg:text-[14px] transition-all group-hover:-translate-x-10 group-hover:rotate-45"></span>
          </div>
        )
      }
      return (
        <Link
          href={uri.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          target={target}
          className={`link-button group hover:cursor-pointer
            w-full flex items-center justify-between text-white
            ${classes}
          `}
        >
          <span className="text-[20px] 3xl:text-[22px] 4xl:text-[25px] 5xl:text-[28px] 6xl:text-[33px] font-light text-white leading-none transition-all group-hover:translate-x-10">{title}</span>
          <span className="ml-6 icon icon-btn-arrow text-[12px] lg:text-[14px] 4xl:text-[16px] 5xl:text-[18px] 6xl:text-[20px] transition-all group-hover:-translate-x-10 group-hover:rotate-45"></span>
        </Link>
      )
    case 'play-video':
      return (
        <div
          className={`play-video transition-all
            flex items-center gap-x-6 px-40 py-15 rounded-[100px] border border-text
            group-hover:border-white group-hover:bg-white group-hover:cursor-pointer
            ${classes}
          `}
        >
          <span className="ft-14 text-white leading-none transition-all group-hover:text-text/80">Voir la vidéo</span>
          <span className="icon icon-play text-text/80"></span>
        </div>
      )
    case 'product-btn':
      return (
        <Link
          href={uri}
          className={`product-btn group inline-block rounded-full px-33 py-10 transition-all hover:bg-white/10 hover:border-transparent
            ${active ? 'bg-white/10 border border-transparent' :'border border-white/30'}
            ${classes}
          `}
        >
          <span className={`ft-14 font-light whitespace-nowrap font-${font} ${active ? 'text-white' : 'text-white/30'} transition-all group-hover:text-white`}>{title}</span>
        </Link>
      )
    case 'tag-btn':
      return (
        <div
          data-tagid={id}
          className={`product-btn inline-block rounded-full px-33 py-10 transition-all group hover:cursor-pointer hover:bg-white/10 hover:border-transparent
            ${active ? 'bg-white/10' :'border border-white/30'}
            ${classes}
          `}
          onClick={handleSearch}
        >
          <span className={`ft-14 font-light whitespace-nowrap transiton-all group-hover:text-white ${active ? 'text-white' : 'text-white/30'}`}>{title}</span>
        </div>
      )
  }
}

export default Btn;