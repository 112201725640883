import { isEmpty } from "lodash";

import Link from "next/link";

import { useGlobalContext } from "../../../contexts"

const SocialMedias = ({classes, linkClasses}) => {
  const { context } = useGlobalContext();

  return (
    <div className={`social-medias flex items-center justify-center ${classes}`}>
      {!isEmpty(context.socialMedias) && context.socialMedias.map((item, index) => (
        <Link
          key={item.socialMedia + index}
          href={item.url}
          target='_blank'
          className={`mr-18 text-white/40 transition-all hover:text-white leading-none relative overflow-hidden ${linkClasses}`}
          aria-label={`Link to ${item.socialMedia}`}
        >
          <span className={`icon icon-${item.socialMedia}`}></span>
          <span className="absolute left-[1000px]">{item.socialMedia}</span>
        </Link>
      ))}
    </div>
  )
}

export default SocialMedias