import { isEmpty } from "lodash";
import { gsap } from "gsap";

import { useRef, useEffect } from "react";

import Link from "next/link"

const SubSubMenuItem = ({ menuItem, closeMenu }) => {

  const descAnim = useRef(null);

  const handleMouseEnter = event => {
    if (window.innerWidth >= 1024 ) {
      let desc = event.currentTarget.lastElementChild;
      descAnim.current = gsap.timeline()
        .to(desc, {height: 'auto', duration: .2});
    }
  }

  const handleMouseLeave = () => {
    if (window.innerWidth >= 1024 && descAnim.current != undefined) {
      descAnim.current.reverse();
    }
  }

  useEffect(() => {
    return () => {
      if (descAnim.current) {
        descAnim.current.kill();
      }
    }
  }, []);

  return (
    <li className="menu-item">
      <Link
        className="block relative mb-8 p-15 rounded-[5px] overflow-hidden transition-all lg:opacity-50 lg:hover:opacity-100"
        href={menuItem.node.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={closeMenu}
      >
        <div className="absolute z-10 w-full h-full top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2 bg-dark-linear-gradient blur-[10px]" />
        <div className="relative z-20 flex items-center justify-between">
          <div className="text-white flex items-center">
            {!isEmpty(menuItem.node.cssClasses[0]) && (
              <span className="flex items-center justify-center mr-10 w-30 xl:mr-20">
                <span className={`second-menu-icons xl:text-[18px] ${menuItem.node.cssClasses[0]}`}></span>
              </span>
            )}
            <span className={`font-light subsubmenuitem ${menuItem?.node?.cssClasses?.[1]}`}>{menuItem.node.label}</span>
          </div>
        </div>
        {!isEmpty(menuItem.node.description) && (
          <div className={`menu-description relative overflow-hidden lg:h-0`}>
            <div className="mt-10 pt-10">
              <span className="block opacity-80 ft-12 leading-[133%] font-extralight text-white">{menuItem.node.description}</span>
            </div>
          </div>
        )}
      </Link>
    </li>
  )
}

export default SubSubMenuItem