import { isEmpty } from "lodash";

import { useEffect } from "react";

import { useLayoutContext } from "../../../contexts";

import Logo from "../commons/logo";
import Nav from "./menu";
import Btn from "../commons/btn";
import BackgroundAudio from "../commons/BackgroundAudio";

const Header = ({logos, mainMenu, templateWhite, isHome, bgWMenuOpen}) => {
  if (isEmpty(logos) || isEmpty(mainMenu)) {
    return null;
  }

  const { headerStatus, setHeaderStatus, menuStatus, reloadFlag } = useLayoutContext();
  const animateHeader = () => {
    window.scrollY > 50 ? setHeaderStatus('header-mini') : setHeaderStatus('header-normal')
  };

  useEffect(() => {
    window.addEventListener('scroll', animateHeader, { passive: true});
    return () => {
      window.removeEventListener("scroll", animateHeader);
    }
  }, []);

  return (
    <header className={`
      fixed z-50 top-0 left-0 w-full py-25 transition-all ${headerStatus} ${menuStatus} ${reloadFlag}
      lg:py-30 px-25 lg:px-30
    `}>
        <div className="
          grid grid-cols-mobile gap-mobile items-center
          lg:grid-cols-normal lg:gap-normal
        ">
          <div className="logo-wrapper relative col-span-1 lg:col-span-4 lg:col-start-5">
            <Logo
              logos={logos}
              type="primary"
              wrapperClasses={`primary relative lg:mx-auto ${templateWhite == true && `opacity-0`}`} />
            <Logo
              logos={logos}
              type="secondary"
              wrapperClasses={`secondary absolute w-full h-full top-1/2 left-0 lg:left-1/2 lg:-translate-x-1/2 -translate-y-1/2 ${templateWhite != true && `opacity-0`}`} />
          </div>
          <div className="col-span-1 flex items-center justify-end lg:order-first lg:justify-start">
            <Btn type='header-test' classes={`mr-20 lg:hidden`} />
            <Nav menu={mainMenu} bgWMenuOpen={bgWMenuOpen} />
            <span className={`menu-audio-sep hidden lg:block flex-[0_0_1px] h-25 xl:h-36 mx-20 xl:mx-30 ${templateWhite == true ? `bg-primary/30` : `bg-white/30`}`}></span>
            <BackgroundAudio />
          </div>
          <div className="hidden col-span-4 col-start-9 lg:flex lg:items-center lg:justify-end">
            <Btn type='shop' />
            <Btn type='header-test' classes={`ml-15`} />
          </div>

        </div>
    </header>
  )
}

export default Header;