import { isEmpty } from 'lodash';
import React from 'react'

const BtnPrimary = React.forwardRef(({ link, ...rest }, ref) => {
  if (isEmpty(link?.title)) { return; }

  return (
    <div className={`flex lg:inline-flex items-center justify-center px-40 py-20 rounded-full border border-actionlight  group transition-all hover:cursor-pointer hover:bg-action hover:border-action`} ref={ref} {...rest}>
        <span className="mr-6 ft-14 text-white">{link?.title}</span>
    </div>
  )
})

export default BtnPrimary;