import { isEmpty } from "lodash";

import { useLayoutContext } from "../../../../contexts";

import SubMenuItem from "./SubMenuItem";
import BlTriptych from "../../commons/BlTriptych";

const SubMenu = ({ menu, nav, closeMenu, setSubmenuStatus, setThisPanel }) => {
  if (isEmpty(menu)) {
    return null;
  }

  const { setPanelOpen, subsubmenuStatus } = useLayoutContext();

  const handleClosePanelClick = () => {
    setThisPanel('panel-close');
    setSubmenuStatus('submenu-close');
    setPanelOpen(false);
  }

  return (
    <nav
      className={`sub-menu w-full h-full absolute top-0 left-full p-20 lg:px-0 opacity-0 lg:w-auto xl:w-[518px] xl:pt-40 ${subsubmenuStatus}`}
    >
      <div className="close flex items-center mb-30 lg:hidden" onClick={handleClosePanelClick}>
        <span className="icon icon-arrow text-white text-[8px] rotate-180"></span>
        <span className="ml-5 text-white ft-12">Retour</span>
      </div>
      <div className="hidden lg:block w-1 h-[95%] absolute top-1/2 left-0 -translate-y-1/2 bg-white/10" />
      <BlTriptych classes="hidden lg:block lg:mb-30 lg:mx-20" />
      <ul className={`submenu-list no-list-style menu-list h-[calc(100%_-_30px)]`}>
        {menu.edges.map((item, index) => (
          <SubMenuItem key={item.node.id} menuItem={item} nav={nav} closeMenu={closeMenu} index={index} />
        ))}
      </ul>
    </nav>
  )
}

export default SubMenu;