import { isEmpty } from "lodash";
import parse from 'html-react-parser';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { SplitText } from "gsap/dist/SplitText";

import { createElement } from "react";
import { useEffect, useRef } from "react";

const Heading = ({ tag, text, classes, titleWChild = false, animate = true }) => {
  if (isEmpty(tag) || isEmpty(text))
    return;

  const title = useRef(null);

  if ( animate == true ) {
    useEffect(() => {
      gsap.registerPlugin(ScrollTrigger, SplitText);

      let ctx = gsap.context((self) => {
        let elToSplit = title.current;
        if (titleWChild == true) {
          elToSplit = title.current?.children[0];
        }

        let textSplitted = new SplitText(elToSplit, {type: "words", tag: "span", wordsClass: "word"});
        textSplitted.words.forEach(word => {
          word.innerHTML = '<span class="inside">' + word.innerHTML + '</span>';
          let inside = word.querySelector('.inside');

          gsap.from(inside, {
            scrollTrigger: {
              trigger: word,
              start: 'top 75%',
            },
            yPercent: 100,
            duration: .5
          })
        });
      }, title);

      return () => {
        ctx.revert();
      }
    }, []);
  }


  let element = createElement(tag,
    {
      className: classes,
      ref: title
    },
    parse(text)
  );

  return element;
}

export default Heading