import Heading from "../../commons/Heading";
import Btn from "../../commons/btn";
import BackgroundFooterNormal from "../../commons/partials/BackgroundFooterNormal.json";
import BackgroundFooterWhite from "../../commons/partials/BackgroundFooterWhite.json";

const WithContact = ({templateWhite}) => {
  let data = BackgroundFooterNormal;
  if (templateWhite == true) {
    data = BackgroundFooterWhite;
  }

  return (
    <div
      className="w-contact container py-50 pb-[250px] md:pt-50 pb-100 md:pt-80 xl:pt-120 md:pb-140 xl:pb-180 background-effects"
      data-backgroundeffects={JSON.stringify(data)}
    >
      <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
        <div className="col-span-2 xs:col-span-10 xs:col-start-2
            sm:col-span-8 sm:col-start-3 text-center md:col-span-10 md:col-start-2 md:text-left">
          <Heading
            tag="div" text={`Une question ? <br /> Un essai gratuit ?`}
            classes="suptitle mb-25 text-white text-shadow-[0_0_3px_#0B212A]"
          />
          <Heading tag="h2" text={"Contactez-nous !"} classes={"text-white mb-50"} />
          <div className="md:flex">
            <Btn type="normal-test" classes={`mb-25 md:mb-0 md:mr-25`} />
            <Btn type="contact-no-fun" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WithContact;