import { isEmpty } from "lodash";

import Link from "next/link";

import { useLayoutContext } from "../../../../contexts";

import SubMenu from "./subMenu";

const MenuItem = ({ menuItem, list, nav, index, closeMenu }) => {
  if (isEmpty(menuItem)) {
    return null;
  }

  const { setPanelOpen, setSubmenuStatus, setNavStatus, thisPanel, setThisPanel } = useLayoutContext();

  if (isEmpty(menuItem.node.childItems.edges)) {
    const handleMouseEnter = () => {
      if (window.innerWidth >= 1024 ) {
        setThisPanel('none');
        setNavStatus('all-close');
      }
    }
    return (
      <li className={`menu-item mb-20 lg:mb-30 lg:px-20 ${index == 0 && `lg:pt-20`}`} onMouseEnter={handleMouseEnter}>
        <Link
          className={`menu-link group flex items-center justify-between text-white transition-all lg:text-white/50 lg:text-[20px] lg:hover:text-white`}
          href={menuItem.node.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          onClick={closeMenu}
        >
            <span>{menuItem.node.label}</span>
        </Link>
      </li>
    )
  } else {
    const handleOpenPanelClick = event => {
      if (window.innerWidth < 1024) {
        let panel = event.currentTarget.parentNode.nextElementSibling;
        setPanelOpen(panel);
        setThisPanel(index);
        setSubmenuStatus('submenu-open');
      }
    }

    const handleMouseEnter = event => {
      if (window.innerWidth >= 1024 ) {
        setThisPanel(index);
        setNavStatus('first-level-open');
      }
    }

    return (
      <li className={`menu-item has-submenu mb-20 lg:mb-30 lg:px-20 group ${index == 0 && `lg:pt-20 xl:pt-40`} ${thisPanel == index ? 'panel-open' : 'panel-close'}`}
        onMouseEnter={handleMouseEnter}
      >
        <div className="flex items-center justify-between">
          <Link
            className={`menu-link text-white transition-all lg:text-white/50 lg:text-[20px] lg:group-hover:text-white`}
            href={menuItem.node.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
            onClick={closeMenu}
          >{menuItem.node.label}
          </Link>
          <span
            className="icon icon-arrow text-white ft-12 transition-all lg:text-white/50 lg:text-[8.5px] lg:group-hover:text-white"
            onClick={handleOpenPanelClick}
          />
        </div>
        <SubMenu
          menu={menuItem.node.childItems}
          nav={nav}
          closeMenu={closeMenu}
          setSubmenuStatus={setSubmenuStatus}
          setThisPanel={setThisPanel}
        />
      </li>
    )
  }
}

export default MenuItem;