import { isEmpty } from "lodash";
import { useEffect, useState } from "react"

const Popup = ({ Opener, openerProps, Content, contentProps }) => {
  // const { siteOverlay } = useAnimationsContext();
  const [popupStatus, setPopupStatus] = useState(false);

  const openClose = () => {
    if (popupStatus) {
      // siteOverlay.current.classList.remove('popup-open');
      document.body.classList.remove('stop-scroll');
    } else {
      // siteOverlay.current.classList.add('popup-open');
      document.body.classList.add('stop-scroll');
    }
    setPopupStatus(!popupStatus);
  }

  const handleClick = (event) => {
    if (!event.target.closest('.popup-inner')) {
      openClose();
    }
  }

  if (!isEmpty(contentProps) && contentProps != undefined) {
    contentProps.closePopup = openClose;
  }

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode == 27 && popupStatus == true) {
        openClose()
      }
    }

    window.addEventListener('keydown', handleEscape, {passive: true});
    return () => {
      window.removeEventListener('keydown', handleClick)
    }
  }, [popupStatus]);

  return (
    <div className={`popup-wrapper default open-${popupStatus}`}>
      <div className="opener" onClick={handleClick}>
        {isEmpty(openerProps) || openerProps == undefined ? Opener : <Opener {...openerProps} />}
      </div>

      <div className="popup scrollbar-hide" onClick={handleClick}>
        <div className="popup-inner">
          <span className="icon icon-error absolute" onClick={openClose} />
          {isEmpty(contentProps) || contentProps == undefined ? Content : (popupStatus == true ? (
            <Content {...contentProps} />
          ) : null)}
        </div>
      </div>
    </div>
  )
}

export default Popup;