import { isEmpty } from "lodash";

import Link from "next/link";

import { useGlobalContext } from "../../../contexts"

import Logo from "../commons/logo";
import SocialMedias from "../commons/SocialMedias";
import WithContact from "./partials/withContact";

const Footer = ({ logos, templateWhite, optionsFooter }) => {
  const { context } = useGlobalContext();
  let date = new Date().getFullYear();

  if (context.isSet == false) {
    return;
  }

  return (
    <footer className={`relative z-10`}>
      {optionsFooter == 'withContact' ? (
        <WithContact templateWhite={templateWhite} />
      ) : null }

      <div className="bg-secondary">
        <div className="container py-40">
          <div className="grid grid-cols-mobile gap-x-mobile xs:grid-cols-normal md:gap-x-normal">
            <div className="col-span-2 xs:col-span-10 xs:col-start-2
              sm:col-span-8 sm:col-start-3 mb-25 lg:col-span-2 lg:mb-0">
              <Logo logos={logos} type="primary" wrapperClasses="relative mx-auto" />
            </div>
            <div className="footer-links
              col-span-2 xs:col-span-10 xs:col-start-2
              sm:col-span-8 sm:col-start-3 flex flex-col mb-25 text-white ft-12 font-light text-center
              lg:mb-0 lg:col-span-7 lg:flex-row lg:items-center
            ">
              {!isEmpty(context?.links?.legals?.url) && (
                <>
                  <Link
                    href={context?.links?.legals?.url?.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
                    target={context?.links?.legals?.blank}
                    className="transition-colors hover:text-action"
                  >{context?.links?.legals?.title} {date}</Link>
                  <span className="hidden lg:block lg:mx-10">—</span>
                </>
              )}
              {!isEmpty(context?.links?.privacyPolicy?.url) && (
                <>
                  <Link
                    href={context?.links?.privacyPolicy?.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
                    target={context?.links?.privacyPolicy?.blank}
                    className="transition-colors hover:text-action"
                  >{context?.links?.privacyPolicy?.title}</Link>
                  <span className="hidden lg:block lg:mx-10">—</span>
                </>
              )}
              <Link
                href="https://www.myclientisrich.com"
                target="_blank"
                className="transition-colors hover:text-action"
              >Conception My Client is Rich</Link>
            </div>
            <div className="col-span-2 xs:col-span-10 xs:col-start-2
              sm:col-span-8 sm:col-start-3 lg:col-span-3 flex justify-center lg:justify-end">
              <SocialMedias linkClasses={'text-[45px] md:text-[27px]'} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;