import { isEmpty } from "lodash";

import Link from "next/link";

import { SubSubMenu } from "./SubSubMenu";
import { useLayoutContext } from "../../../../contexts";

const SubMenuItem = ({menuItem, nav, closeMenu, index}) => {
  if (isEmpty(menuItem)) {
    return null;
  }

  const { setSubPanelOpen, setSubsubmenuStatus, setNavStatus, thisSubPanel, setThisSubPanel } = useLayoutContext();

  if (isEmpty(menuItem.node.childItems.edges)) {
    const handleMouseEnter = () => {
      if (window.innerWidth >= 1024) {
        setNavStatus('first-level-open');
        setThisSubPanel('none');
      }
    }
    return (
      <li className="menu-item relative mb-8 p-15 rounded-[5px] overflow-hidden lg:mx-20" onMouseEnter={handleMouseEnter}>
        <div className="absolute z-10 w-full h-full top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2 bg-dark-linear-gradient blur-[10px]" />
        <Link
          href={menuItem.node.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
          className="relative z-20 flex items-center justify-between transition-all lg:opacity-50 lg:hover:opacity-100"
          onClick={closeMenu}
        >
          <div className="text-white flex items-center" >
            {!isEmpty(menuItem.node.cssClasses) && (
              <span className="flex items-center justify-center mr-10 w-30">
                <span className={`second-menu-icons ${menuItem.node.cssClasses}`}></span>
              </span>
            )}
            <span className="xl:text-[18px]">{menuItem.node.label}</span>
          </div>
        </Link>
      </li>
    )
  } else {

    const handleOpenSubPanelClick = event => {
      if (window.innerWidth < 1024) {
        let subPanel = event.currentTarget.parentNode.parentNode.nextElementSibling;
        setThisSubPanel(index);
        setSubsubmenuStatus('subsubmenu-open');
        setSubPanelOpen(subPanel);
      }
    }

    const handleMouseEnter = event => {
      if (window.innerWidth >= 1024 ) {
        setNavStatus('first-level-open second-level-open');
        setThisSubPanel(index);
      }
    }


    return (
      <li className={`menu-item has-subsubmenu lg:px-20 ${thisSubPanel == index ? 'subpanel-open' : 'sub-panel-close'}`} onMouseEnter={handleMouseEnter}>
        <div className="relative mb-8 px-15 rounded-[5px] overflow-hidden">
          <div className="absolute z-10 w-full h-full top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2 bg-dark-linear-gradient blur-[10px]" />
          <div className="relative z-20 flex items-center justify-between lg:opacity-50 hover:opacity-100">
            <Link
              href={menuItem.node.url.replace(process.env.NEXT_PUBLIC_WORDPRESS_SITE, '')}
              className="text-white flex items-center py-15"
              onClick={closeMenu}
            >
              {!isEmpty(menuItem.node.cssClasses) && (
                <span className="flex items-center justify-center mr-10 w-30">
                  <span className={`second-menu-icons ${menuItem.node.cssClasses}`}></span>
                </span>
              )}
              <span className="xl:text-[18px]">{menuItem.node.label}</span>
            </Link>
            <span className="icon icon-arrow text-white ft-12" onClick={handleOpenSubPanelClick}></span>
          </div>
        </div>
        <SubSubMenu
          menu={menuItem.node.childItems.edges}
          closeMenu={closeMenu}
          setThisSubPanel={setThisSubPanel}
        />
      </li>
    )
  }
}

export default SubMenuItem;