import { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "../../../contexts"

const BackgroundAudio = () => {
  const { context } = useGlobalContext();
  const [audio, setAudio] = useState(false);

  const audioRef = useRef();
  const playPromise = useRef();

  const handleAudio = (event) => {
    if (event.currentTarget.classList.contains('icon-sound-off')) {
      event.currentTarget.classList.remove('icon-sound-off');
      event.currentTarget.classList.add('icon-sound-on');
      playPromise.current = audioRef.current.play();
    } else {
      event.currentTarget.classList.remove('icon-sound-on');
      event.currentTarget.classList.add('icon-sound-off');
      playPromise.current.then(_ => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      })
    }
  }

  useEffect(() => {
    const wwChecker = () => {
      if (window.innerWidth >= 1024) {
        setAudio(true);
      } else {
        setAudio(false);
      }
    }
    wwChecker();

    window.addEventListener('resize', wwChecker, { passive: true});
    return () => {
      window.removeEventListener("resize", wwChecker);
    }
  }, []);

  return (
    <div className="background-audio">
      {audio == true && (
        <div className="flex items-center justify-center">
          <span
            className="
              icon text-white icon-sound-off xl:text-[22px]
              transition-all hover:cursor-pointer hover:text-action"
            onClick={handleAudio}
          />
          <audio src={context.backgroundAudio.guid} preload="none" playsInline loop={true} ref={audioRef}></audio>
        </div>
      )}
    </div>
  )
}

export default BackgroundAudio