import { useLayoutContext } from "../../../../contexts";
import SubSubMenuItem from "./SubSubMenuItem"

export const SubSubMenu = ({ menu, closeMenu, setThisSubPanel }) => {

  const { setSubPanelOpen, setSubsubmenuStatus } = useLayoutContext();

  const handleCloseSubPanelClick = () => {
    setThisSubPanel('subpanel-close');
    setSubsubmenuStatus('subsubmenu-close');
    setSubPanelOpen(false);
  }

  return (
    <nav className="
      subsub-menu w-full h-full absolute top-0 left-full p-20 opacity-0
      lg:w-[295px] xl:w-[341px]
    ">
      <div className="close flex items-center mb-30 lg:hidden" onClick={handleCloseSubPanelClick}>
        <span className="icon icon-arrow text-white text-[8px] rotate-180"></span>
        <span className="ml-5 text-white ft-12">Retour</span>
      </div>
      <div className="hidden lg:block w-1 h-[95%] absolute top-1/2 left-0 -translate-y-1/2 bg-white/10" />
      <ul className="subsubmenu-list no-list-style h-[calc(100%_-_30px)] overflow-scroll scrollbar-hide">
        {menu.map( (item) => (
          <SubSubMenuItem key={item.node.id} menuItem={item} closeMenu={closeMenu} />
        ))}
      </ul>
    </nav>
  )
}
