import { isEmpty } from "lodash";
import gsap from "gsap";

import { useEffect, useRef } from "react";

import { useLayoutContext } from "../../../../contexts";

import MenuItem from "./menuItem";
import SocialMedias from "../../commons/SocialMedias";

const Nav = ({menu, bgWMenuOpen}) => {
  if (isEmpty(menu)) {
    return null;
  }

  const {
    menuStatus, setMenuStatus,
    submenuStatus, navStatus,
    panelOpen, setPanelOpen,
    subPanelOpen, setSubPanelOpen,
    setNavStatus
  } = useLayoutContext();

  const menuAnimRef = useRef(null);
  const menuRefs = useRef([]);

  // Handle burger click
  const handleBurgerClick = () => {
    menuStatus == 'menu-close' ? openMenu() : closeMenu()
  };
  const openMenu = () => {
    if (menuAnimRef.current == null) {
      menuAnimRef.current = gsap.timeline({paused: true})
      .to(menuRefs.current.topLine, {rotate: 90, y: -2.5, duration: 0.2})
      .to(menuRefs.current.middleLine, {rotate: -90, y: -8.5, duration: 0.2}, '<')
      .to(menuRefs.current.bottomLine, {x: 3, y: -3, duration: 0.2}, '<')
      .to(menuRefs.current.topLine, {width: 22, rotate: 45, x: 2, y: -2.5, duration: 0.2, delay: .4})
      .to(menuRefs.current.middleLine, {width: 22, rotate: -45, x: -5 , y: -8.5, duration: 0.2}, '<')
      .to(menuRefs.current.bottomLine, {y: 10, opacity: 0, duration: 0.2}, '<');
    }

    menuAnimRef.current.play();
    menuRefs.current.menuLabel.innerHTML = 'Fermer';
    document.body.classList.add('menu-stop-scroll');
    setMenuStatus('menu-open');
  }
  const closeMenu = () => {
    menuAnimRef.current.reverse();
    if (!isEmpty(subPanelOpen)) {
      setTimeout(() => {
        subPanelOpen.querySelector('.close').click();
        setSubPanelOpen(false);
      }, 600)
    }
    if (!isEmpty(panelOpen)) {
      setTimeout(() => {
        panelOpen.querySelector('.close').click();
        setPanelOpen(false);
      }, 600)
    }
    menuRefs.current.menuLabel.innerHTML = 'Menu';
    document.body.classList.remove('menu-stop-scroll');
    setMenuStatus('menu-close');
  }

  const handleClickOutside = () => {
    closeMenu();
  }

  const handleMouseLeave = () => {
    if (window.innerWidth >= 1024 ) {
      setNavStatus('all-close');
    }
  }

  useEffect(() => {
    if (bgWMenuOpen.current != null) {
      bgWMenuOpen.current.addEventListener('click', handleClickOutside, {passive: true});
    }
    return () => {
      if (bgWMenuOpen.current != null) {
        bgWMenuOpen.current.removeEventListener('click', handleClickOutside, {passive: true});
      }
      if (menuAnimRef.current) {
        menuAnimRef.current.kill();
      }
    }
  }, []);

  return (
    <div className={`menu-wrapper`}>
      <div className="group burger-group hover:cursor-pointer lg:flex lg:items-center" onClick={handleBurgerClick}>
        <div
          className="burger-wrapper-wrapper transition-all
            lg:w-40 lg:h-40 lg:bg-text/30 lg:rounded-full lg:flex lg:items-center lg:justify-center lg:mr-10
            xl:w-60 xl:h-60 transition-all
          "
          ref={(el) => (menuRefs.current['burgerClicker'] = el)}
        >
          <div className="burger-wrapper w-18 h-13 flex flex-col justify-between lg:translate-y-1" >
            <span className="line first w-full h-1 bg-white origin-top-left" ref={(el) => (menuRefs.current['topLine'] = el)}></span>
            <span className="line second w-full h-1 bg-white origin-top-right" ref={(el) => (menuRefs.current['middleLine'] = el)}></span>
            <span className="line last w-12 h-1 bg-white origin-top-left" ref={(el) => (menuRefs.current['bottomLine'] = el)}></span>
          </div>
        </div>
        <span
          className="burger-menu-text hidden lg:block text-white ft-13 font-light transition-all"
          ref={(el) => (menuRefs.current['menuLabel'] = el)}
        >Menu</span>
      </div>
      <nav
        className={`
          menu max-w-[375px] fixed z-50 left-full w-[calc(100vw_-_40px)] overflow-hidden rounded-[10px]
          lg:w-auto lg:max-w-none lg:left-0 lg:-translate-x-full ${navStatus}
        `}
        ref={(el) => (menuRefs.current['nav'] = el)} onMouseLeave={handleMouseLeave}
      >
        <div className="w-full h-full absolute z-10 top-0 left-0 bg-dark-linear-gradient backdrop-blur-[25px]" />
        <ul
          className={`menu-list no-list-style h-[calc(100%_-_80px)] relative z-20 p-20 rounded-[10px] lg:w-[295px] lg:h-full lg:p-0 ${submenuStatus}`}
          ref={(el) => (menuRefs.current['list'] = el)}
        >
          {menu.map( (item , index) => (
            <MenuItem
              key={item.node.id}
              menuItem={item}
              list={menuRefs.current['list']}
              nav={menuRefs.current['nav']}
              index={index}
              closeMenu={closeMenu}
            />
          ))}
        </ul>
        <SocialMedias
          classes={`
            social-medias opacity-0 fixed z-40 left-1/2 bottom-20 -translate-x-1/2 pointer-events-none
            lg:left-0 lg:translate-x-60
          `}
          linkClasses={`text-[27px]`}
        />
      </nav>
    </div>
  )
}

export default Nav;