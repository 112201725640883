import { isEmpty } from "lodash";

import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

const Logo = ({logos, type, wrapperClasses}) => {
  if (isEmpty(logos)) {
    return null;
  }

  if (isEmpty(type)) {
    type = 'primary'
  }

  let logo;
  if (type == 'primary') {
    logo = logos.mainLogo;
  } else if (type = 'secondary') {
    logo = logos.secondaryLogo;
  }

  const router = useRouter();
  if (router.asPath == '/') {
    return (
      <div
        className={`
          block w-full aspect-[142/25]
          max-w-[142px] lg:max-w-[180px] xl:max-w-[220px] 2xl:max-w-[254px]
          ${wrapperClasses}
        `}>
            <Image
              src={logo?.sourceUrl}
              sizes={logo?.sizes}
              alt={logo.title}
              fill={true}
              className={`main-logo object-cover z-10 transition-all duration-300`}
              priority={true}
            />
      </div>
    )
  } else {
    return (
      <Link
        href="/"
        className={`
          block w-full aspect-[142/25]
          max-w-[142px] lg:max-w-[180px] xl:max-w-[220px] 2xl:max-w-[254px]
          ${wrapperClasses}
        `}>
            <Image
              src={logo?.sourceUrl}
              sizes={logo?.sizes}
              alt={logo.title}
              fill={true}
              className={`main-logo object-cover z-10 transition-all duration-300`}
              priority={true}
            />
      </Link>
    )
  }

}

export default Logo;