import { isEmpty } from "lodash";
import parse from 'html-react-parser';

const Wysiwyg = ({ content, classes }) => {
  if (isEmpty(content))
    return;

  return (
    <div className={`wysiwyg ${classes}`}>{parse(content)}</div>
  )
}

export default Wysiwyg