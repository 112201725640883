import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';

import {useEffect, useState} from 'react';

import { useGlobalContext } from '../../../../contexts';

import PopupCookies from './PopupCookies';
import BtnTertiary from '../buttons/BtnTertiary';
import Wysiwyg from '../Wysiwyg';
import Popup from '../Popup';
import BtnPrimary from '../buttons/BtnPrimary';
import GoogleTagManager from '../GoogleTagManager';

const CookiesComponent = () => {
  const { context } = useGlobalContext();

  // states
  const [showCookieContainer, setShowCookieContainer] = useState(false);
  const [privacyState, setPrivacyState] = useState({
    necessary: true,
    analytics: true,
    publicity: false
  });

  /**
   * If accept all cookies
   */
  const acceptCookies = () => {
    const cookieValues = {
      necessary: true,
      analytics: true,
      publicity: true
    };
    Cookies.set('preferences', JSON.stringify(cookieValues), {
      expires: 365,
      path: '/',
      sameSite: 'lax'
    });
    setPrivacyState(cookieValues);
  };

  /**
   * If denied all cookies
   * (just necessary do not change)
   */
  const deniedAllCookies = () => {
    const cookieValues = {
      necessary: true,
      analytics: false,
      publicity: false
    };
    Cookies.set('preferences', JSON.stringify(cookieValues), {
      expires: 365,
      path: '/',
      sameSite: 'lax'
    });
    setPrivacyState(cookieValues);
  }

  /**
   * Set preference from user
   * @param {object} preferences
   */
  const handleSavePreferences = (preferences) => {
    setPrivacyState(preferences);
    const lowercasePreferences = {};
    Object.keys(preferences).forEach(key => {
      lowercasePreferences[key.toLowerCase()] = preferences[key];
    });
    Cookies.set('preferences', JSON.stringify(lowercasePreferences), {
      expires: 365,
      path: '/',
      sameSite: 'lax'
    });
    setShowCookieContainer(false);
  };

  /**
   * If no cookies set, open container cookie
   */
  useEffect(() => {
    if(Cookies.get('preferences') == undefined) {
      setShowCookieContainer(true)
    }
  }, [])

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('consent', 'update', {
      'ad_user_data': privacyState?.publicity ? 'granted' : 'denied',
      'ad_personalization': privacyState?.publicity ? 'granted' : 'denied',
      'ad_storage': privacyState?.publicity ? 'granted' : 'denied',
      'analytics_storage': privacyState?.analytics ? 'granted' : 'denied'
    });

    const updatePrivacyState = () => {
      const preferences = Cookies.get('preferences');
      if (preferences) {
        const parsedPreferences = JSON.parse(preferences);
        if (JSON.stringify(parsedPreferences) !== JSON.stringify(privacyState)) {
          setPrivacyState(parsedPreferences);
        }
      }
    };
    updatePrivacyState();
  }, [privacyState]);

  return (
    <>
      <div className={`cookies-container flex fixed flex-col image-common-border-radius ${showCookieContainer == true ? "block" : "hidden"}`}>
        <div className={`wrapper-content`}>
          <Wysiwyg content={context?.cookies?.cookieBanner} />
          <Popup
            opener={<p className="btn-politique">Voir notre politique relative aux cookies.</p>}
            content={<Wysiwyg content={context?.privacyPolicies} />}
          />
        </div>
        <div className={`wrapper-btn flex flex-col sm:flex-row sm:justify-between`}>
          <Popup
            Opener={BtnTertiary}
            openerProps={{link: { title: 'Personnaliser' }, noarrow: 'true'}}
            Content={PopupCookies}
            contentProps={{
              deniedAllCookies: deniedAllCookies,
              acceptAllCookies: acceptCookies,
              onSavePreferences: handleSavePreferences,
              setShowCookieContainer: setShowCookieContainer,
              cookiesData: context?.cookies?.cookietypes
            }}
          />
          <BtnPrimary link={{ title: 'Refuser'}} nobackground={'true'} onClick={() => {deniedAllCookies(); setShowCookieContainer(false)}}/>
          <BtnPrimary link={{ title: 'Accepter'}} onClick={() => {acceptCookies(); setShowCookieContainer(false)}}/>
        </div>
      </div>
      {!isEmpty(process.env.GTM_CODE) && (
        <GoogleTagManager code={process.env.GTM_CODE} privacyState={privacyState} />
      )}
    </>
  );
};

export default CookiesComponent;
